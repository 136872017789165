/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DetailsLink } from '../../components/LazyList/DetailsLink';
import LazyList from '../../components/LazyList/LazyList';
import {
  defaultOnDownload,
  defaultOnSearchChange,
} from '../../components/LazyList/LazyListHelpers';
import { getHardwareMonthReport } from '../../services/fetchServices/HardwareApi/getHardwareMonthReport';
import {
  ColumnName,
  composeColumnsDefinition,
  Filters,
  mapHardware,
  monthNames,
  NUMBER_OF_HARDWARE_FETCHED,
} from './HardwareListHelpers';
import useHardwareListData from './useHardwareListData';
import './style.css';

const useStyles = makeStyles({
  monthReportDatePicker: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    position: 'absolute',
    '& div': {
      visibility: 'hidden',
    },
  },
  button: {
    position: 'relative',
    top: '10px',
    right: '100px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: ' background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '12px',
    borderRadius: '50%',
    '&:hover': {
      color: '#ffd242',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      outline: '0',
    },
  },
  spinner: {
    marginRight: '10px',
  },
});
const materialTheme = () =>
  createTheme({
    palette: {
      primary: { main: '#ffd242' },
    },
    typography: {
      fontSize: 18,
      fontFamily: ['ModeratJIT', 'sans-serif'].join(','),
    },
    overrides: {
      MuiPaper: {
        elevation2: {
          width: '30%',
          minWidth: '30%',
          maxWidth: '30%',
        },
      },
      ...(window.location.pathname === '/hardware' && {
        MUIDataTableToolbar: {
          actions: {
            '& > span': {
              position: 'relative',
              right: '-50px',
            },
          },
        },
      }),
    },
  });

type Props = {
  parents?: boolean;
  initialFilters?: Filters;
  actionComponents?: (rowData: string[]) => JSX.Element[];
  currentHardwareId?: number;
};

const HardwareListTable = ({
  initialFilters,
  actionComponents,
  parents = false,
  currentHardwareId,
}: Props) => {
  const {
    state: {
      totalHardware,
      filteredHardware,
      loadedHardware,
      activeFilters,
      availableFilters,
      displayedColumns,
      activeSort,
      searchText,
      isFetching,
      isCalendarOpen,
      monthReportDate,
      isMonthReportFetching,
    },
    setState,
    onColumnsChangeCallback,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onDownload,
  } = useHardwareListData(parents, initialFilters);

  const classes = useStyles();

  useEffect(() => {
    if (monthReportDate) {
      const fetchedDate = monthReportDate.replace(' ', '_');
      setState((prevState) => ({
        ...prevState,
        isMonthReportFetching: true,
      }));
      getHardwareMonthReport({ date: monthReportDate })
        .then(defaultOnDownload(`HardwareMonthReport_${fetchedDate}.xlsx`))
        .then(() =>
          setState((prevState) => ({
            ...prevState,
            isMonthReportFetching: false,
            monthReportDate: null,
          })),
        );
    }
  }, [monthReportDate, setState]);

  const onInfiniteScroll = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        numberOfRequestedHardware:
          prevState.numberOfRequestedHardware + NUMBER_OF_HARDWARE_FETCHED,
      })),
    [setState],
  );

  const allActionComponents = (rowData: string[]) =>
    actionComponents
      ? [
          ...actionComponents(rowData),
          <DetailsLink link={`/hardware/${rowData[ColumnName.id]}`} />,
        ]
      : [<DetailsLink link={`/hardware/${rowData[ColumnName.id]}`} />];

  const handleDateChange = (date: Date | null) => {
    const selectedMonth = date && monthNames[date.getMonth()];
    const selectedYear = date && date.getFullYear();
    const reportDate = `${selectedMonth} ${selectedYear}`;

    setState((prevState) => ({
      ...prevState,
      monthReportDate: reportDate,
    }));
  };

  const toggleCalendar = () => {
    setState((prevState) => ({
      ...prevState,
      isCalendarOpen: !isCalendarOpen,
    }));
  };

  const columns = composeColumnsDefinition(
    activeFilters,
    availableFilters,
    activeSort,
    displayedColumns,
  );

  const options = {
    serverSide: true,
    pagination: false,
    rowsPerPage: 5000,
    print: false,
    filter: true,
    filterType: 'multiselect',
    serverSideFilterList: Object.values(activeFilters),
    searchText,
    onDownload,
    selectableRows: 'none',
    rowHover: false,
    searchOpen: true,
    textLabels: {
      toolbar: {
        downloadCsv: 'Download Report',
      },
    },
    setCellProps: (cellValue: any, rowIndex: any, columnIndex: any) => ({
      style: { background: 'rgba(255, 0, 0, 1)' },
    }),

    setRowProps: (row: any) => {
      const checkBoxName =
        row[9].props.children.props.children &&
        row[9].props.children.props.children.type.displayName;
      if (checkBoxName === 'CheckBoxIcon') {
        return {
          style: { background: 'rgba(4, 159, 149, 0.2)' },
        };
      }
    },
    customToolbar: () => (
      <>
        {window.location.pathname === '/hardware' && (
          <Tooltip title="Download Month Report">
            <button className={classes.button} onClick={toggleCalendar} type="button">
              {!isMonthReportFetching ? (
                <GetAppIcon />
              ) : (
                <CircularProgress className={classes.spinner} size={20} />
              )}
            </button>
          </Tooltip>
        )}
        <div className={classes.monthReportDatePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={['year', 'month']}
              open={isCalendarOpen}
              value={monthReportDate}
              onChange={handleDateChange}
              onClose={toggleCalendar}
            />
          </MuiPickersUtilsProvider>
        </div>
      </>
    ),
  };

  const data = loadedHardware
    .filter((h) => (currentHardwareId ? h.id !== currentHardwareId : true))
    .map(mapHardware);

  return (
    <LazyList
      data={data}
      columns={columns}
      options={options}
      onFilterChange={contextEnchancedOnFiltersChange}
      onSortChange={contextEnchancedOnSortChange}
      onSearchChange={defaultOnSearchChange(setState)}
      onColumnsChange={onColumnsChangeCallback}
      totalItems={totalHardware}
      filteredItems={filteredHardware}
      onInfiniteScrollCallback={onInfiniteScroll}
      isFetching={isFetching}
      actionComponents={allActionComponents}
      muiTheme={materialTheme}
    />
  );
};

export default HardwareListTable;
