import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      bottom: '5px',
      textAlign: 'center',
      marginTop: '1%',
    },
  }),
);

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography>JIT Atlas v2.0.1</Typography>
      <Typography>&copy; 2022 Jit Team Sp. z o.o. all rights reserved</Typography>
    </footer>
  );
};

export default Footer;
